.notesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.notesGrid {
  width: 100%;
}

.note {
  height: 200px;
  min-width: 150px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.notesGridContainer {
  padding: 0;
}
.notesRow {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.note:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.customWidth {
  width: 100%;
  background: #f7f0d5 !important;
}

@media (min-width: 768px) {
  /* Adjust the breakpoint as needed */
  .customWidth {
    /* width: 50%; */
  }
}
