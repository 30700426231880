body {
  margin: 0;
  background: #fafafa;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background-color: #ffc700;
  /* --background-color: #bbe6fc; */
  --bs-primary-rgb: #12296c;
}

body {
  font-family: sans-serif;
  background-color: var(--background-color);
  color: var(--bs-primary-rgb);
  font-weight: 300;
  font-size: 1.2rem;
  background-repeat: no-repeat;
  background-image: url(../../public/img/noise-bg.png);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 800;
}
h1 {
  font-size: 4rem;
}

.container-fluid-custom {
  max-width: 1600px;
}

/* Button */
.btn {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  border-radius: 0.24rem;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary-rgb);
  --bs-btn-border-color: var(--bs-primary-rgb);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0043a8;
  --bs-btn-hover-border-color: #0043a8;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-rgb);
  --bs-btn-active-border-color: var(--bs-primary-rgb);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary-rgb);
  --bs-btn-disabled-border-color: var(--bs-primary-rgb);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary-rgb);
  --bs-btn-border-color: var(--bs-primary-rgb);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0043a8;
  --bs-btn-hover-border-color: #0043a8;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-rgb);
  --bs-btn-active-border-color: var(--bs-primary-rgb);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary-rgb);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary-rgb);
  --bs-gradient: none;
}

.pagination {
  --bs-pagination-color: var(--bs-primary-rgb);
  --bs-pagination-active-bg: var(--bs-primary-rgb);
  --bs-pagination-active-border-color: var(--bs-primary-rgb);
}

/* Home */
@media (min-width: 1200px) {
  .home {
    background: url(../../public/img/banner1.svg) no-repeat,
      var(--background-color);
    background-size: contain;
    background-position: left;
    transform: scaleX(-1);
  }
}
.home-view {
  min-height: calc(100vh - 180px);
  padding: 20px 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.home-col {
}

/* .home-title::before {
  content: url(../../public/img/stars.svg);
  position: absolute;
  left: -50px;
  top: -30px;
}

.home-title::after {
  content: url(../../public/img/stars.svg);
  position: absolute;
  right: -50px;
  bottom: -30px;
} */

.feature-list {
  font-size: 1.2rem;
  list-style-type: decimal-leading-zero;
  margin: 0;
}

.badge {
  border: none;
  border-radius: 2px;
  background: var(--bs-primary-rgb);
}

.custom-navbar {
  color: var(--bs-primary-rgb) !important;
}
.custom-navbar .navbar-brand {
  color: var(--bs-primary-rgb) !important;
}
.custom-navbar .nav-link {
  color: var(--bs-primary-rgb) !important;
}

.notes-view {
  min-height: calc(100vh - 340px);
  padding: 20px 0;
  width: 100%;
}

.h-notes-x {
  height: 20vh;
}

/* styles.css or your preferred stylesheet */
.custom-toast {
  background-color: #12296c !important;
  color: #fff !important; /* Optional: Add text color if needed */
}
