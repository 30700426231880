.blockCenter {
  display: block;
  margin-right: auto;
  margin-top: 50px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.width100 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
